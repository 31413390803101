import React from "react"
import {Link} from "gatsby"
import Navigation from "../components/navigationModule"
import Styles from "../components/aboutus.module.css"
import SEO from "../components/seo"
import ScrollAnimation from 'react-animate-on-scroll'
import get from 'lodash/get'
import Img from 'gatsby-image'
import {Icon} from 'react-icons-kit'
import {notepad} from 'react-icons-kit/ikons/notepad'
import {quoteLeft} from 'react-icons-kit/fa/quoteLeft'
import Footer from "../components/footer"
import Slider from "react-slick";
import {concatenateContentfullParagraphs} from "../util/util";

class aboutUs extends React.Component {
    render() {
        const content = get(this, 'props.data.allContentfulAboutUsPage.nodes')[0];
        console.log('about us content: ', content);
        var settings = {
            dots: true,
            infinite: true,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            autoplay: true,
            slidesToScroll: 1
        };
        return (
            <>
                <SEO title="About"/>
                <Navigation onScrollProp={null}/>

                <section>
                    <div className={Styles.aboutus_bannerimage}>
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12">
                                    <div className={Styles.text}>
                                        <h2>About</h2>
                                        <p>
                                            who we are
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="container">
                        <div className="row">
                            <div className="col-md-1 col-12 col-sm-12">
                            </div>
                            <div className="col-md-10 col-12 col-sm-12">
                                <div className="row">
                                    {/*<div className="col-md-6">*/}
                                    {/*  <div className={Styles.company_profile_image}>*/}
                                    {/*  <Img alt={content.companyProfileHeading} sizes={content.companyProfileImage.sizes}/>*/}
                                    {/* */}
                                    {/*  </div>*/}
                                    {/*   */}
                                    {/*</div>*/}
                                    <div className="col-md-12">
                                        <ScrollAnimation animateIn="fadeIn">
                                            <div className={Styles.aboutus_info}>

                                                <Icon className={Styles.ibox_icon} icon={notepad}/>
                                                <h3>
                                                    {content.companyProfileHeading}</h3>
                                                <p>
                                                    {/*{content.companyProfileText.content[0].content[0].value}*/}
                                                    {concatenateContentfullParagraphs(content.companyProfileText.content)}
                                                </p>


                                            </div>
                                        </ScrollAnimation>
                                        <Link className={Styles.projects_button} title="VIEW OUR PROJECTS"
                                              aria-label="VIEW OUR PROJECTS" to="/projects">View Our Projects</Link>


                                    </div>


                                </div>


                            </div>
                            <div className="col-md-1 col-12 col-sm-12">

                            </div>

                        </div>
                    </div>
                    <div className={Styles.testmonials}>
                        <div className="overlay"></div>
                        <div className="row">
                            <div className="col-md-3 col-12 col-sm-12">
                            </div>
                            <div className="col-md-6 col-12 col-sm-12">
                                <div className={Styles.testmonials_wrap}>
                                    <h2>
                                        {content.sectionTwoHeading}
                                    </h2>

                                    <div className={Styles.testmonials_message}>
                                      <span className="quotes">
                                      <Icon icon={quoteLeft}/>
                                      <Icon className={Styles.rightSpeech} icon={quoteLeft}/>
                                      </span>
                                        <Slider {...settings}>
                                            <div>
                                                <p>Our work with Tandem and Stark LTD aimed to implement Apartments in Westlands region. We have been pleased with Tandem and Stark’s ability to manage project cost and provide information required in a timely manner.

What we appreciate most about Tandem and Stark Ltd is their flexibility and ability to deliver quality cost engineering services through the lifetime project.

We would not hesitate to recommend them for any projects as quantity surveyors.

                                                  
                                                </p>
                                                <p>Limojade Management, Customer</p>
                                            </div>
                                          
                                            <div>
                                                <p>
                                                Karibu Homes is one of the largest affordable housing projects underway in the region with over 1,000 homes under construction in Athi River.

Tandem and Stark won the job in a competitive tender due to their ability to work tirelessly in the planning stages with all our iterations and come up with cost based solutions or the design team to work with.

I would highly recommend the firm for their ability to deliver a cost plan for the project.
                                                </p>
                                                <p> Karibu Homes, Customer   </p>
                                            </div>
                                            <div>
                                                <p>
                                                Our work with Tandem and Stark Ltd aimed to implement a housing in the Kitengela region. We have been pleased with Tandem and Stark’s ability to manage project cost and provide information required in a timely manner.

What we appreciate most about Tandem and Stark is their flexibility and ability to deliver quality cost engineering services through the lifetime of the project.

We would not hesitate to recommend them for any projects as quantity surveyors.
                                                </p>
                                                <p>Esham Park Ltd, Customer</p>
                                            </div>
                                            <div>
                                                <p>
                                                Brookside Dairy Ltd’s Project Team has been pleased and impressed with both the methodology and people we dealt with throughout the project cycles.

They were very thorough and flexible in developing a plan that suited our needs and unique requirements. The planned work and process were spelled out in detail in our contract and the statement of work objectives and expectations were met in full.

They showed professionalism in all dealing with us and the members of various consulting company.
                                                </p>
                                                <p>Brookside Dairy Ltd, Customer</p>
                                            </div>
                                            
                                        </Slider>

                                    </div>

                                </div>

                            </div>
                            <div className="col-md-3 col-12 col-sm-12">
                            </div>
                        </div>

                    </div>


                </section>

                <Footer/>
            </>
        )
    }
}

export default aboutUs

export const query = graphql`
    query AboutQuery {

        allContentfulAboutUsPage {
            nodes {
                companyProfileImage {
                    description
                }
                companyProfileHeading
                companyProfileText {
                    content {
                        content {
                            value
                        }
                    }
                }
                companyProfileImage {
                    sizes(resizingBehavior: SCALE) {
                        ...GatsbyContentfulSizes_withWebp
                    }
                }
                sectionTwoHeading
                sectionTwoText {
                    content {
                        content {
                            value
                        }
                    }
                }
            }
        }



    }
`; 

